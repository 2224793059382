import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import TimeportsLogin from "./pages/TimeportsLogin";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import AzureLogin from "./pages/AzureLogin";
import DonePage from "./pages/DonePage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <TimeportsLogin />,
  },
  {
    path: "/login",
    element: <AzureLogin />,
  },
  {
    path: "/done",
    element: <DonePage />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
