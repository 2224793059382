import { useState } from "react";
import logo from "../assets/logo-3.png";

const AzureUsername = ({ onNextButtonClick, onUsernameChange, username }) => {
  const [error, setError] = useState("");

  const validateLogin = (str) => {
    if (!str) {
      return false;
    }
    const regex = /.+@jit\.team/;
    return regex.test(str);
  };

  const handleNextButtonClick = () => {
    if (!validateLogin(username)) {
      setError(
        "Wprowadź prawidłowy adres e-mail, numer telefonu lub nazwę użytkownika Skype'a."
      );
      return;
    }
    setError(false);
    onNextButtonClick();
  };

  return (
    <div className="w-[440px] min-h-[200px] p-11 bg-white">
      <img src={logo} alt="banner-logo" className="h-14" />
      <p className="my-3 text-2xl font-semibold">zaloguj sie</p>
      {error && <p className="text-[15px] leading-5 text-red-500">{error}</p>}
      <input
        placeholder="adres e-mail telefon lub skype"
        id="username"
        className={`w-full border-b 
        ${error ? "border-b-red-500" : "border-b-stone-500"} 
        ${!error && "focus:border-b-azureBlue hover:border-b-azureBlue"} 
        py-1.5 placeholder:font-light placeholder:text-gray-500 text-gray-700 focus:outline-none`}
        value={username}
        onChange={onUsernameChange}
      />
      <p className="min-h-[36px] text-[13px] text-azureBlue mt-4 hover:cursor-pointer hover:underline hover:text-stone-700">
        nie mozesz uzyskac dostepu do konta ?
      </p>
      <div className="flex gap-1 justify-end">
        <button
          className="px-10 py-1 bg-azureBlue text-white"
          onClick={handleNextButtonClick}
        >
          Dalej
        </button>
      </div>
    </div>
  );
};

export default AzureUsername;
