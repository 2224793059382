import companyLogo from "../assets/logo-black.png";

const DonePage = () => {
  return (
    <div className="min-h-screen bg-neutral-200 flex flex-col">
      <div className="w-full h-12 bg-black px-36 flex items-center">
        <img alt="logo" src={companyLogo} className="h-10" />
      </div>
      <div className="h-full w-full flex flex-col justify-center items-center flex-1">
        <h1 className="text-4xl leading-normal font-semibold">Gotowe!</h1>
        <p>Kontakt ze strony HR w celu wyjaśnienia różnic nastąpi w ciągu dwóch dni roboczych.</p>
      </div>
    </div>
  );
};

export default DonePage;
