import { useState } from "react";
import AzureUsername from "../components/AzureUsername";
import AzurePassword from "../components/AzurePassword";

const AzureLogin = () => {
  const [activeStep, setActiveStep] = useState("username");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleNextButtonClick = () => {
    setActiveStep("password");
  };

  const handlePreviousButtonClick = () => {
    setActiveStep("username");
  };

  const handleLogin = async () => {
    const params = new URLSearchParams({ username });
    const response = await fetch(
      `/api/user/login?${params}`,
      {
        method: "POST",
      }
    );
    return response
  };

  return (
    <>
      <div className="min-h-screen bg-stone-950 bg-center" />
      <div className="absolute top-1/2 right-1/2 translate-x-1/2 -translate-y-1/2">
        {activeStep === "username" ? (
          <AzureUsername
            onNextButtonClick={handleNextButtonClick}
            onUsernameChange={(e) => setUsername(e.target.value)}
            username={username}
          />
        ) : (
          <AzurePassword
            onPrevButtonClick={handlePreviousButtonClick}
            onPasswordChange={(e) => setPassword(e.target.value)}
            onSubmit={handleLogin}
            password={password}
            username={username}
          />
        )}
      </div>
    </>
  );
};

export default AzureLogin;
